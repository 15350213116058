<!-- 化合物库信息管理系 -->
<template>
  <div class="clinic">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'化合物库信息管理系统'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="applications-container">
      <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
      <div class="app-text">{{ appText }}</div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner3.png"),
      business: {
        text: "对于医药研发企业、CRO、科研机构等在研发过程中所产生的大量化合物、天然产物以及与之相关的科学数据，是研究机构和企业的宝贵财富，同时也是研究机构和企业研究水平和核心竞争力的体现。采用信息管理系统管理化合物的收集、处理和存储，科学规范管理化合物的信息数据，帮助管理者及时掌握研发项目进度，通过化合物结构式查询，随时了解历史研发信息，及时分享研发信息，加快研发进程，充分实现历史化合物研发信息被参考和重复利用，节约企业资源。",
        Imgs: [
          {
            url: require("@/assets/images/cases/content/compound01.png")
          }
        ]
      },
      appText: "为医药研发企业、CRO、科研机构管理化合物的收集、出入库、存储管理，可设置业务流程，系统支持化合物信息数据管理，实现化合物信息录入、检索、统计、分析、实验记录等功能。同时，系统可提供公众信息查询平台，以便让公众或其它合作单位查询化合物信息，并在线提交化合物或其它信息。为满足化合物的专业特性，系统支持化学结构处理功能，可以在线输入化学结构、存储化学结构，或通过化学结构式进行信息检索，支持精确结构、子结构、相似结构检索。支持通过化合物类型、来源、分子量、分子式等各种方式对化合物进行筛选。具有数据导出功能，可导出Smiles、inchi等格式的数据，供虚拟筛选之用。系统在南方海洋科学与工程广东省实验室（珠海）天然产物资源库、乐威医药（天津）有限公司、江苏凯威尔医药科技有限公司等科研机构、医药研发企业运行使用。"

    }
  }
}
</script>

<style lang="less" scoped>

</style>